
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import ProgressCell from '@/components/dataTables/cell/ProgressCell.vue'
  import { mapGetters } from 'vuex'
  import { Auto } from '@/entities/public'

@Component({
  components: { ProgressCell },
  computed: {
    ...mapGetters('resources/form', ['backup']),
  },
})
  export default class FormTitle extends Vue {
  @Prop({ type: Boolean, default: false }) display: boolean
  @Prop({ type: Boolean, default: true }) showTitle: boolean
  @Prop({ type: String, default: '' }) title: string
  @Prop({ type: String, default: undefined }) subtitle: string
  @Prop({ type: String, default: undefined }) slug: string
  backup!: Record<string, any>

  mounted () {
    this.$emit('height-size', (this.$el as HTMLElement).offsetHeight)
  }

  get titleSizeH1 () {
    const { title } = this

    return title.length < 14
  }

  get isInspection () {
    const { slug } = this

    return slug?.includes('inspection')
  }

  get inspectedValue () {
    const { backup } = this

    return backup?.inspection
  }

  get isAuto () {
    const { inspectedValue } = this

    return inspectedValue?.auto?.version?.version?.model?.brand?.name
  }

  get auto (): Auto {
    const { inspectedValue } = this
    return inspectedValue?.auto
  }
  }
