
  import { Component, Prop } from 'vue-property-decorator'
  import { ButtonType, Input, Layout as ILayout, LayoutProps } from '@/entities/public/Resource/interfaces/form'
  import { VContainer, VRow, VCol, VSpacer } from 'vuetify/lib/components'
  import { Dynamic } from './dynamic'
  import DynamicForm from './DynamicForm.vue'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component({
  components: {
    VContainer,
    VRow,
    VCol,
    VSpacer,
    Field: () => import('./DynamicField.vue'),
    DForm: () => import('./DynamicForm.vue'),
  },
})
  export default class DynamicLayout extends Dynamic {
  @Prop({ type: Array, required: true }) children!: Array<ILayout | string>;
  @Prop({ type: Object, required: true }) fields!: Record<string, Input>

  declare properties: LayoutProps;

  created () {
    this.updateBind()
  }

  get childrenMap () {
    const { children, fields, fetch } = this

    return children.map(child => {
      if (typeof child === 'string') {
        const _child = fields[child]
        if (!_child) throw new Error(`Unregistered input ${child}`)

        return _child.component !== 'form'
          ? { component: 'field', props: { ..._child, fetch } }
          : {
            component: 'd-form',
            props: { ..._child.properties, child: true, preventRemote: true },
            target: _child.target,
            parent: _child.parent,
          }
      }

      return {
        component: 'dynamic-layout',
        props: { ...child, fields, fetch },
      }
    })
  }

  get childrenNodes () {
    const { childrenMap } = this

    return childrenMap.map(({ component, props, target, parent }) => {
      return {
        target,
        parent,
        component,
        data: {
          attrs: props,
          props,
          on: {
            update: ({ value, path }) => {
              if (target) path = !path ? target : [target, path].join('.')
              this.notifyChange({ value, path })
            },
            'nested:form': e => this.$emit('nested:form', e),
          },
        },
      }
    })
  }

  assignParent (child, path, parent) {
    if (!path || !child || child.constructor.name === 'Object') return

    if (!(path in child) || !child[path]) {
      delete child[path]
      Object.defineProperty(child, path, { get () { return parent }, enumerable: false })
    }
  }

  render (h) {
    const { bind } = this
    if (bind.hidden) return

    const { component, childrenNodes, value } = this

    const data = {
      attrs: bind,
      props: bind,
    }

    const children = childrenNodes.map(({ component, data, target, parent }) => {
      const val = target ? getObjectValueByPath(value, target) : value
      if (target) this.assignParent(val, parent, value)

      return h(component, {
        ...data,
        props: {
          ...data.props,
          value: val,
        },
      })
    })

    return h(component, data, children)
  }

  get submittable (): Array<DynamicLayout | DynamicForm> {
    const { $children } = this
    return $children.filter(child => child instanceof DynamicLayout || child instanceof DynamicForm) as Array<DynamicLayout | DynamicForm>
  }

  async submit (type: ButtonType): Promise<void> {
    const { submittable } = this
    await Promise.all(submittable.map(child => child.submit(type)))
  }

  async childrenUpdate (value, forceRender?) {
    const { $children } = this
    await this.onChangeValue(value)

    await Promise.all($children.map(child => {
      if (child instanceof DynamicLayout) return child.childrenUpdate(value, forceRender)
      if (child instanceof DynamicForm) return child.onParentChange()
      if (child instanceof Dynamic) return child.onChangeValue(value).then(() => forceRender && child.forceUpdate())
    }))

    // TODO: Perform affected check to forceUpdate only if required
    this.forceUpdate()
  }
  }
