
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
  import { BaseView } from './view'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    Agenda: () => import('@/components/forms/fields/agenda/Agenda.vue'),
    FormTitle: () => import('@/components/forms/FormTitle.vue'),
  },
})
  export default class DesktopForm extends BaseView {
  @Ref() readonly centerButtons!: HTMLDivElement
  @Prop({
    type: [String, Object, Number],
    default: null,
  }) changeDetected: string | number | Object | Array<Record<string, any>>

  private minContainerHeight = 500
  private maxContainerHeight = 500
  tab = 0
  private innerSideHeight = 0

  get items () {
    const { isAgenda, showHistory } = this

    const tabs = ['Detalle']
    if (isAgenda) tabs.splice(0, 0, 'Agenda')
    if (showHistory) tabs.push('Historico')

    return tabs
  }

  get displayDetails () {
    const { showDetails, staticForm, uid, hiddenDetails } = this
    this.setHeight()
    return (showDetails || (staticForm && uid)) && !hiddenDetails
  }

  setHeightTitle (height) {
    const { centerButtons } = this

    this.innerHeight = centerButtons?.clientHeight + height
    this.innerSideHeight = height
  }

  @Debounce(500)
  setHeight () {
    const { $children } = this
    if (!$children.length) return
    const tabHeight = 50
    this.maxContainerHeight = $children[0].$el.clientHeight
    this.minContainerHeight = this.maxContainerHeight - tabHeight
  }

  get change () {
    const { containerHeight, changeDetected } = this
    return { containerHeight, changeDetected }
  }

  @Watch('active', { immediate: true, deep: true })
  @Debounce(250)
  onFormChange () {
    this.setHeight()
  }

  @Watch('change', { immediate: true, deep: true })
  @Debounce(250)
  setHeightStatic (val) {
    if (val.containerHeight !== null) {
      this.minContainerHeight = val.containerHeight
      this.setHeight()
    }
  }

  mounted () {
    this.$nextTick(() => {
      const scrollElement = this?.$el?.querySelector('.v-card__text')
      if (scrollElement) {
        scrollElement.addEventListener('scroll', this.handleScroll)
      }
    })
  }

  beforeDestroy () {
    const scrollElement = this?.$el?.querySelector('.v-card__text')
    if (scrollElement) {
      scrollElement.removeEventListener('scroll', this.handleScroll)
    }
  }

  @Debounce(250)
  handleScroll (event) {
    const { dense } = this
    if (!event.target || !dense) return null

    const scrollPosition = event.target.scrollTop

    this.$emit('hide-title', scrollPosition !== 0)
  }
  }
