import { FieldName, Input, TargetType } from './public/Resource/interfaces/form'
import { setValueByPath } from '@/utils/data'

function getDefaultValue (type: TargetType) {
  switch (type) {
    case 'boolean':
      return false
    case 'string':
      return ''
    case 'number':
      return 0
    case 'object':
      return {}
    case 'array':
      return []
    default:
      return undefined
  }
}

export class Generic {
  static init (obj: any, fields: Record<FieldName, Input>) {
    Object.entries(fields).forEach(([key, { target: shadow, init }]) => {
      if (!init?.value && !init?.type) return

      const { target = shadow, value, type } = init
      // TODO: If it's already initialized, the precedent value must be preserved. (edit:forms)
      setValueByPath(obj, target, value || getDefaultValue(type))
    })

    return obj
  }
}
