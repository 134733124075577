import { cast } from '@/utils/general'
import i18n from '@/plugins/i18n'
import { DetailRecord, Details } from '@/utils/generalInterface'

export const STOCK_STATUS = [
  '🟢 DISPONIBLE',
  '🟠 HABILITACIÓN',
  '🟣 RESERVADO',
  '🟦 VENTA EN PROCESO',
  '🟥 SUBASTAR',
]
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MISSING_PHOTO = require('@/assets/vehicle/missingPhoto.png')
export const UNAVAILABLE_PHOTO = require('@/assets/vehicle/imageUnavailable.svg')
const DEFAULT_PIC = {
  thumbnails: {
    url: MISSING_PHOTO,
    full: {
      url: MISSING_PHOTO,
    },
    large: {
      url: MISSING_PHOTO,
    },
    small: {
      url: MISSING_PHOTO,
    },
  },
  metadata: {
    default: true,
  },
} as Picture
const GENERAL = ['transmission', 'traction', 'fuel']
const EXTERIOR = ['bodywork', 'color']
const INSIDE = ['seats', 'airbag type']
const COMFORT = ['comfort equipment']

export class Vehicle {
  public record: VehicleRecord;
  private _details?: Details;
  private _posts?: Array<Post>;

  constructor (record: VehicleRecord) {
    this.record = record
  }

  get id (): number {
    if (!this.record.id) return -1
    return this.record.id
  }

  get plate (): string {
    const { ppu } = this.record
    return ppu
  }

  get fuel (): string {
    const { fuel } = this.details

    return fuel
  }

  get year (): number {
    const { year } = this.details

    return year
  }

  get version (): string {
    const { version } = this.details

    return version
  }

  get description (): string {
    const { brand, model } = this.details
    return `${brand} - ${model}`
  }

  get status (): string {
    const { status = 'DRAFT' } = this.details
    return status as string
  }

  get price (): string {
    const { price = 0 } = this.details
    return '$' + price.toLocaleString('de-DE')
  }

  get mileage (): string {
    const { mileage = 0 } = this.details
    return mileage.toLocaleString('de-DE') + ' km'
  }

  get isInStock (): boolean {
    return STOCK_STATUS.includes(this.status)
  }

  get pictures (): Array<Picture> {
    const { picture = [DEFAULT_PIC] } = this.details
    return picture as Array<Picture>
  }

  get mainPhoto (): Picture {
    const { picture = DEFAULT_PIC } = this.details

    if (typeof picture === 'string') return JSON.parse(picture) as Picture

    return picture as Picture
  }

  get details (): Details {
    if (!this._details) {
      return this.loadDetails(this.record.details as Array<DetailRecord>)
    }

    return this._details
  }

  get posts (): Array<Post> {
    if (!this._posts) {
      return this.loadPosts(this.record.posts as Array<Post>)
    }
    return this._posts
  }

  get characteristics (): Array<Characteristic> {
    return [
      { title: i18n.t('general'), values: this.extractGroup(GENERAL) },
      { title: i18n.t('exterior'), values: this.extractGroup(EXTERIOR) },
      { title: i18n.t('equipment'), values: this.extractGroup(COMFORT) },
      { title: i18n.t('inside'), values: this.extractGroup(INSIDE) },
    ]
  }

  get technicalData (): TechnicalData {
    return {
      id: this.id,
      ppu: this.plate,
      brand: this.details.brand,
      model: this.details.model,
      version: this.details.version,
      year: this.details.year,
    }
  }

  loadPosts (posts: Array<Post>): Array<Post> {
    this._posts = posts
    return posts
  }

  loadDetails (details: Array<DetailRecord>): Details {
    const isStatus = this.record.status && this.record.status[0]
    const statusFixed = isStatus
      ? (this.record.status[0].value as string)
      : 'unclassified'

    const target = this._details || {
      status: statusFixed,
    }

    details.forEach(
      ({ attribute: { name, type, multiple }, value, metadata }) => {
        const val = cast(value, type, metadata)

        let detail = target[name] || (multiple ? [] : val)

        if (multiple) detail.push(val)
        else detail = val

        target[name] = detail
      }
    )

    this._details = target
    return target
  }

  private extractGroup (group: Array<string>) {
    const details = this.details
    const options = []

    for (const detail in details) {
      if (group.includes(detail)) {
        options.push(`${[i18n.t(detail)]} : ${this.details[detail]}`)
      }
    }

    return options
  }
}

export interface VehicleRecord {
  id: number
  uuid: string
  ppu: string
  description: string
  status: Array<DetailRecord>
  details?: Array<DetailRecord>
  posts?: Array<Post>
}

// TODO: Define properties for the pictures.
export interface Picture {
  [key: string]: string | boolean | Date | null | Record<string, unknown>

  metadata: Record<string, unknown>
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Characteristic {
  title: string | any
  values: Array<string>
}

export interface Post {
  id: number
  status: string
  platformId: number
  url?: string
}

export interface TechnicalData {
  id: number
  ppu: string
  brand: string
  model: string
  version: string
  year: number
}
