import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import DynamicForm from '@/components/forms/DynamicForm.vue'
import { Form } from '@/entities/public/Resource/metadata'
import { debounce } from '@/utils/vuetify/helpers'
import { extendsObj } from '@/utils/data'
import { Resource } from '@/entities/public'
import { buildCondition } from '@/utils/conditional'

export interface DForm extends DynamicForm {
  onModelChanged: (form) => Promise<boolean>
  submit: (type: string) => Promise<void>
  cancel: () => void
}

@Component({
  components: {
    DynamicForm,
    ViewContainerCard: () => import('@/components/core/ViewContainerCard.vue'),
    GDetail: () => import('@/components/core/view/GDetail.vue'),
    GTimeline: () => import('@/components/core/view/GTimeline.vue'),
  },
  methods: {
    ...mapActions('resources/form', ['updateValue']),
    ...mapMutations('resources/form', ['setBackup']),
  },
  computed: {
    ...mapGetters('resources/form', ['resource', 'active', 'path', 'backup']),
    ...mapGetters('app', ['isResize']),
  },
})
export class BaseView extends Vue {
  @Prop({ type: Boolean, default: false }) dense!: boolean
  @Prop({ type: Number }) uid?: number
  @Prop({ type: Number }) parentId?: number
  @Prop({ type: String }) parentModel?: string
  @Prop({ type: String }) customModel?: string
  @Prop({ type: String }) customSlug?: string
  @Prop({ type: Number }) customId?: number
  @Prop({ type: Boolean, default: false }) showHistory!: boolean
  @Prop({ type: Boolean, default: false }) dryRun!: boolean
  @Prop({ type: Boolean, default: false }) hiddenDetails!: boolean
  @Prop({ type: Object, default: null }) isAgenda!: { title: string, date: string, allowSelect: boolean }

  @Prop({ type: Number }) containerHeight!: number
  @Prop({ type: Number }) containerWidth!: number

  @Prop({ type: Boolean, default: false }) staticForm!: boolean
  @Prop({ type: Object }) staticData: any

  $refs!: {
    form: DForm
  }

  // --------------------------------------------------------------
  render = false
  valid = false
  isResize!: number
  protected innerHeight = 0
  protected reRender: () => void

  // --------------------------------------------------------------
  private resource!: Resource
  private path!: string
  private backup: any
  private active!: any
  private updateValue!: (payload: { value: any, path?: string }) => any

  // --------------------------------------------------------------
  created () {
    this.reRender = debounce(() => this.render = true, 250)
    const { active } = this
    if (!active || active.constructor.name !== 'Object') this.updateValue({ value: {} })
  }

  get structure (): Form {
    const { resource, staticData } = this
    if (!resource && !staticData?.metadata) return null

    return staticData?.metadata as Form || resource?.metadata as Form
  }

  get model () {
    const { structure } = this
    return structure?.model
  }

  get slug () {
    const { resource } = this
    if (!resource) return

    const { slug } = resource
    return slug
  }

  get title () {
    const { resource } = this
    return resource?.name
  }

  get subtitle () {
    const { structure } = this
    return structure?.form?.subtitle
  }

  get bind () {
    const { model, slug, path, uid, staticData, active, parentId, parentModel, customModel, customId, customSlug } = this

    return {
      model: customModel || model,
      slug: customSlug || slug,
      parentId,
      parentModel,
      uid: customId || (!path ? uid : active?.id),
      key: `${model}-${slug}`,
      value: staticData?.data || active,
    }
  }

  get form () {
    const { bind, dryRun, path, backup } = this
    if (backup && bind?.value) {
      Object.assign(bind.value, backup)
    }

    return {
      bind: {
        ...bind,
        preventRemote: dryRun || Boolean(path),
      },
      on: {
        update: async value => {
          const form = await this.updateValue(value)
          this.valid = await this.$refs.form?.onModelChanged(form)
        },
        success: result => {
          if (dryRun) console.log('GenericView:success:result', result)
          if (!dryRun || path) this.$router.back()
        },
        'nested:form': pathArray => {
          const { currentRoute: { query } } = this.$router
          const { nested } = query
          const clean = pathArray.filter(_ => _)
          const updated = { nested: typeof nested === 'string' ? [nested].concat(clean) : clean }
          return this.$router.push({ query: extendsObj(updated, query) })
        },
      },
    }
  }

  get details () {
    const { bind, render } = this

    return {
      ...bind,
      render,
    }
  }

  get showDetails () {
    // TODO: Create a better approach to only run this once.
    const { bind: { value }, structure } = this
    if (!structure || !value) return false

    const { details } = structure
    if (!details) return false

    const { condition } = details
    if (!condition) return true
    return buildCondition(value, condition).result
  }

  submit () {
    return this.$refs.form.submit('submit')
  }

  cancel () {
    this.$refs.form.cancel()
    this.$router.back()
  }
}
