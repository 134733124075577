
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { Deal } from '@/entities/crm'
  import { fixPrice, isValidNumber } from '@/utils/general'
  import { GForm } from '@/components/forms/GForm'
  import { Inspection } from '@/entities/purchase'
  import { plainToInstance } from 'class-transformer'
  import { Stock } from '@/entities/sales'
  import { Auto } from '@/entities/public'

@Component({
  methods: { isValidNumber },
  components: { StockPhotos },
})
  export default class AutoResume extends GForm {
  @Prop({ type: Deal, default: null }) deal!: Deal
  @Prop({ type: Stock, default: null }) stock!: Stock
  km = null
  owner = null

  alternativeAttributes: [] = []
  inspection: Inspection = plainToInstance(Inspection, {})

  // Methods
  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation?.attributes || []
      } else {
        cont++
      }
    }

    return generation?.attributes || []
  }

  // Getters
  get auto () {
    const { deal } = this

    if (!deal) return null

    return deal?.auto
  }

  get traction () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att?.component?.slug === 'traction')?.val || []
  }

  get fuel () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att.component?.slug === 'fuel')?.val || []
  }

  get transmission () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att.component?.slug === 'transmission')?.val || []
  }

  get mileage () {
    const { deal, km } = this

    const att = km ? { val: km } : deal?.autoAttributes?.sort((a, b) => b.id - a.id)?.find(att => att.slug === 'mileage')
    return fixPrice(att?.val, false)
  }

  get owners () {
    const { deal, owner } = this

    const att = owner ? { val: owner } : deal?.autoAttributes?.find(att => att.slug === 'owners_number')

    return att?.val || '-'
  }

  // Watchers
  @Watch('deal', { immediate: true, deep: true })
  async onAutoChange (val: Deal) {
    if (!val?.id) return

    if (val?.auto && !this.alternativeAttributes?.length) {
      this.alternativeAttributes = await this.findAlternativesAttributes(val.auto)
    }

    if (!val?.autoAttributes?.length && !this.stock?.id) {
      this.deal.autoAttributes = await this.fetchData({
        query: { name: 'find', model: 'DealAutoAttribute' },
        filter: { id_deal: { _eq: val.id } },
        force: true,
      })
    }

    if (!val?.appraisal?.id && !this.stock?.id) {
      this.deal.appraisals = (await this.fetchData({
        query: { name: 'find', model: 'Appraisal' },
        filter: { id_deal: { _eq: val.id } },
        force: true,
      }))
    }

    if (!this.inspection?.id && val?.id && !this.stock?.id) {
      this.inspection = (await this.fetchData({
        query: { name: 'findLite', model: 'Inspection', order: { id: 'desc' } },
        filter: { appraisal: { id_deal: { _eq: val.id } } },
        force: true,
      }))[0]
    }

    await this.getAutoAttribute(val?.auto)

    this.$emit('auto-loaded')
  }

  get allowDisplay () {
    const { inspection, stock, appraisal } = this

    return inspection?.id || stock?.id || appraisal?.id
  }

  get appraisal () {
    const { deal } = this

    return deal?.appraisal
  }

  async getAutoAttribute (value) {
    if (value instanceof Auto) {
      const { id } = value

      if (!id) return

      const filter = {
        _and: [
          { id_auto: { _eq: id } },
          { component: { slug: { _in: ['mileage', 'owners_number'] } } },
        ],
      }
      const attributes = await this.fetchData({
        query: { name: 'find', model: 'AttributeView', order: { id_attribute: 'desc' } },
        filter,
        force: true,
      })

      this.km = attributes?.find(attr => attr.slug === 'mileage')?.val
      const owner = attributes?.find(attr => attr.slug === 'owners_number')?.val

      this.owner = owner ? owner.includes('Único') ? 'Único' : owner : owner
    }
  }
  }
